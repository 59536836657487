"use strict";

new WOW().init();

(function ($) {
  $(document).ready(function () {
    // HEADER-BURGER ========================================================================================================================================================
    $('.header__burger').on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).toggleClass("active");
        $('body').css('overflowX', 'hidden');
        $('body').css('overflowY', 'auto');
        $('header').addClass('header_mobile');
      } else {
        $('header').removeClass('header_mobile');
        $(this).toggleClass("active");
        $('body').css('overflow', 'hidden');
      }
    }); // SUB-MENU =======================================================================================

    $(".header__prod-categories-item").click(function () {
      if (window.matchMedia('(max-width: 991px)').matches) {
        $(".header-prod__submenu").not($(this).find(".header-prod__submenu")).removeClass("open");
        $(this).find(".header-prod__submenu").toggleClass("open");
        $(".header__prod-categories-item").not($(this)).removeClass('open');
        $(this).toggleClass('open');
      }
    }); // HEADER-SCROLL ========================================================================================================================================================

    var scrollChange = 1;

    function headerScroll(params) {
      var scroll = $(window).scrollTop();

      if (scroll >= scrollChange) {
        $('.header').addClass('header_scroll');
      } else {
        $('.header').removeClass('header_scroll');
      }
    }

    $(document).on('scroll', function () {
      headerScroll();
    });
    headerScroll(); // append to mobile menu ============================================================

    function appendTel() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        $('.header__item-menu').appendTo('.menu');
      } else {
        $('.header__item-menu').prependTo('.header-mobile-menu');
      }
    }

    appendTel();
    $(window).on('resize', function () {
      appendTel();
    }); // GET YEAR ========================================================================================================================================================

    if (document.querySelectorAll('.year').length > 0) {
      var yearSpan = document.querySelector('.year');
      var date = new Date();
      var yearFull = date.getFullYear();
      yearSpan.innerHTML = yearFull;
    } // TESTIMONIALS SLIDER ===========================================================


    if ($(".testimonials-slider").length > 0) {
      var testimonialSlider = new Swiper('.testimonials-slider', {
        slidesPerView: 3,
        speed: 1000,
        spaceBetween: 30,
        loop: true,
        autoplay: true,
        pagination: {
          el: ".pagination__testimonials",
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      });
    }

    ; // FEATURED PRODUCTS SLIDER ===========================================================

    if ($(".products-slider").length > 0) {
      var productSlider = new Swiper('.products-slider', {
        slidesPerView: 4,
        speed: 1000,
        spaceBetween: 30,
        loop: true,
        // autoplay: true,
        pagination: {
          el: ".pagination__featured-products",
          clickable: true
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }
      });
    }

    ; // lOGO SLIDER ========================================================================================================================================================

    if ($(".logo-slider__section").length > 0) {
      var logoSlider = new Swiper('.swiper-logo', {
        slidesPerView: 6,
        speed: 1000,
        spaceBetween: 30,
        loop: true,
        autoplay: true,
        pagination: {
          el: ".pagination-logo",
          clickable: true
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 30
          }
        }
      });
    } //HERO SLIDER ============================================================================


    if ($(".hero__slider").length > 0) {
      var heroSlider = new Swiper('.hero__slider', {
        slidesPerView: 1,
        speed: 3000,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 1500
        },
        effect: 'fade',
        pagination: {
          el: ".pagination-hero-slider",
          clickable: true
        }
      });
    }

    ; // HOME COUNTER ======================================================

    var counters = document.querySelectorAll('.counter');
    var observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var counter = entry.target;
          var currentValue = 0;
          var target = parseInt(counter.getAttribute('data-target'));
          var step = Math.ceil(target / 100);

          var updateCounter = function updateCounter() {
            if (currentValue < target) {
              currentValue += step;

              if (currentValue > target) {
                currentValue = target;
              }

              counter.textContent = currentValue;
              requestAnimationFrame(updateCounter);
            }
          };

          updateCounter();
          observer.unobserve(counter);
        }
      });
    }, {
      threshold: 0.5
    });
    counters.forEach(function (counter) {
      observer.observe(counter);
    }); //RATING ================================================================================================================================================================

    $(".star__rating").each(function () {
      var rating = parseFloat($(this).data('rating'));
      var roundedRating = Math.floor(rating);
      var result = '';
      var fullStar = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4693 7.14856L13.321 6.20799L10.5651 0.32924C10.3593 -0.109747 9.64012 -0.109747 9.43429 0.32924L6.67929 6.20799L0.530958 7.14856C0.0259576 7.22618 -0.175709 7.84126 0.178458 8.2043L4.64512 12.7895L3.58929 19.2716C3.50512 19.7865 4.05429 20.173 4.50846 19.9201L10.0001 16.8806L15.4918 19.9209C15.9418 20.1713 16.496 19.7924 16.411 19.2724L15.3551 12.7903L19.8218 8.20513C20.176 7.84126 19.9735 7.22618 19.4693 7.14856Z" fill="#FFC107"/></svg>';
      var emptyStar = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9816 6.66188L12.8683 6.42022L10.1125 0.541506C10.1125 0.541494 10.1125 0.541481 10.1125 0.541468C10.1103 0.536939 10.106 0.529426 10.0893 0.520227C10.07 0.509585 10.0386 0.5 9.99977 0.5C9.96095 0.5 9.92955 0.509585 9.91024 0.520227C9.89361 0.52939 9.88924 0.536881 9.8871 0.541415L12.9816 6.66188ZM12.9816 6.66188L13.2454 6.70224L19.3933 7.64274C19.3933 7.64275 19.3934 7.64275 19.3934 7.64276C19.4922 7.65807 19.5368 7.78116 19.4636 7.85639L14.997 12.4414L14.8212 12.6219L14.8617 12.8707L15.9175 19.3528L15.9176 19.3531C15.9228 19.3852 15.9178 19.4072 15.9104 19.4235C15.9022 19.4419 15.8874 19.4606 15.8661 19.4754C15.8233 19.5052 15.7772 19.5075 15.735 19.484L15.734 19.4835L10.2424 16.4431L10.0002 16.3091L9.75806 16.4431L4.26639 19.4826L4.26528 19.4832C4.17854 19.5315 4.06571 19.4569 4.0828 19.3523L4.08285 19.352L5.13868 12.8698L5.1792 12.6211L5.00334 12.4406L0.53667 7.8554L0.536419 7.85515C0.46389 7.7808 0.507397 7.65814 0.60687 7.64277C0.606905 7.64277 0.606939 7.64276 0.606974 7.64276L6.75496 6.70224L7.01883 6.66188L7.1321 6.42017L9.88706 0.541506L12.9816 6.66188Z" stroke="#FFC107"/></svg>';
      $(this).empty();

      for (var i = 0; i < 5; i++) {
        if (roundedRating > i) {
          result += fullStar;
        } else {
          result += emptyStar;
        }
      }

      $(this).html(result);
    }); //SELECT 2 ==============================================================================================================================================

    $('.js-example-basic-single').each(function () {
      $(this).select2();
    }); // checkbox ========================================================================================================================================================

    $('.sidebar-widget a').click(function (event) {
      event.preventDefault();
      $(this).toggleClass('wd-active');
    }); //SHOW ALL BRANDS BTN ==========================================================================

    var initialVisibleBrands = 10;
    var brandsCards = $('.filter__brand li');
    var showMoreBtn = $('#filterShowMore');

    if (showMoreBtn.length > 0) {
      brandsCards.slice(initialVisibleBrands).hide();

      if (brandsCards.length <= initialVisibleBrands) {
        showMoreBtn.hide();
      }
    }

    showMoreBtn.on('click', function (event) {
      var buttonAttr = $(this).attr('data-state');

      if (buttonAttr === 'hideBrands') {
        $(this).attr('data-state', 'showBrands');
        $(this).text('Hide');
        brandsCards.slice(initialVisibleBrands).slideDown();
      } else {
        $(this).attr('data-state', 'hideBrands');
        $(this).text('More..');
        brandsCards.slice(initialVisibleBrands).slideUp();
      }
    }); //RANGE SLIDER =========================================================================

    $("#rangeSlider").ionRangeSlider({
      type: 'double',
      min: 0,
      max: 1000,
      from: 0,
      to: 1000,
      grid: false,
      hide_min_max: true,
      hide_from_to: true,
      onChange: function onChange(data) {
        $("#min-price").val("$" + data.from);
        $("#max-price").val("$" + data.to);
      }
    }); // slideToggle filter block=============================================================================================================

    var filterBtn = $('.filter-category__button');
    var filterWrap = $('.filter-products-block');
    var filterCloseBtn = $('.filter-close-btn');

    var toggleFilterWrap = function toggleFilterWrap(event) {
      event.stopPropagation();

      if ($(window).width() < 768) {
        filterWrap.toggleClass('open');
      }
    };

    var closeFilterWrap = function closeFilterWrap(event) {
      event.stopPropagation();
      filterWrap.removeClass('open');
    };

    filterBtn.on('click', toggleFilterWrap);
    $(window).on('resize', function () {
      filterWrap.removeClass('open');
    });
    filterCloseBtn.on('click', closeFilterWrap);
    $(document).on('click', function (e) {
      if ($(window).width() < 768 && filterWrap.hasClass('open') && !filterBtn.is(e.target) && !filterWrap.is(e.target) && filterWrap.has(e.target).length === 0) {
        closeFilterWrap(e);
      }
    }); //PRODUCT SLIDER====================================================================================================

    if ($(".swiper-thumbs").length > 0) {
      var sliderThumbs = new Swiper('.swiper-thumbs', {
        direction: 'vertical',
        slidesPerView: 5,
        spaceBetween: 10,
        freeMode: true,
        breakpoints: {
          0: {
            direction: 'horizontal'
          },
          992: {
            direction: 'vertical'
          }
        }
      });

      if ($(".swiper-images").length > 0) {
        var sliderImages = new Swiper('.swiper-images', {
          slidesPerView: 1,
          spaceBetween: 32,
          thumbs: {
            swiper: sliderThumbs
          },
          breakpoints: {
            0: {
              direction: 'horizontal'
            },
            992: {
              direction: 'vertical'
            }
          }
        });
      }
    } // tabs


    $('[data-tab]').on('click', function () {
      $('[data-tab-content]').addClass('hidden');
      $('[data-tab]').removeClass('active');
      var contentBox = $('#' + $(this).data('tab'));
      contentBox.removeClass('hidden');
      $(this).addClass('active');
    }); //input-password===========================================================================================================================

    $(".show-password-input").on("click", function () {
      var passwordInput = $(this).prev("input");

      if (passwordInput.attr("type") === "password") {
        passwordInput.attr("type", "text");
        $(this).addClass("display-password");
      } else {
        passwordInput.attr("type", "password");
        $(this).removeClass("display-password");
      }
    }); //PAYMENT METHOD RADIO BTN

    $('input[name="radio-control-wc-payment-method-options"]').on('change', function () {
      if ($('#radio-control-wc-payment-method-options-bacs').is(':checked')) {
        $('.select__payments-block').slideDown();
      } else {
        $('.select__payments-block').slideUp();
      }
    });
  });
})(jQuery); // Style map========================================================================================================================================================


var stylesLocation = [{
  "elementType": "geometry",
  "stylers": [{
    "color": "#f5f5f5"
  }]
}, {
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#616161"
  }]
}, {
  "elementType": "labels.text.stroke",
  "stylers": [{
    "color": "#f5f5f5"
  }]
}, {
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#bdbdbd"
  }]
}, {
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [{
    "color": "#eeeeee"
  }]
}, {
  "featureType": "poi",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#757575"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "geometry",
  "stylers": [{
    "color": "#e5e5e5"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}, {
  "featureType": "road",
  "elementType": "geometry",
  "stylers": [{
    "color": "#ffffff"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#757575"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "geometry",
  "stylers": [{
    "color": "#dadada"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#616161"
  }]
}, {
  "featureType": "road.local",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}, {
  "featureType": "transit.line",
  "elementType": "geometry",
  "stylers": [{
    "color": "#e5e5e5"
  }]
}, {
  "featureType": "transit.station",
  "elementType": "geometry",
  "stylers": [{
    "color": "#eeeeee"
  }]
}, {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [{
    "color": "#c9c9c9"
  }]
}, {
  "featureType": "water",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}];